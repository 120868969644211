import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../formRequestHome/formRequestHome'
import { Context } from '../context/provider'
import UploadDocuments from '../uploadDocuments/uploadDocuments'
import UploadSummary from '../uploadSummary/uploadSummary'
import { getStorageItem } from '../../utilities/getStorageItem'
import Checkbox from '../checkbox/checkbox'
import styles from './personalizedTag.module.scss'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function PersonalizedTag() {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');

  const [replacement, setReplacement] = useState(false)

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Upload documents',
    'Summary',
    'Payment'
  ]

  const formName = 'Personalized Tag'
  const description = 'Personalized plates may be placed on personal vehicles in lieu of a regular tag. You are allowed no more than five (5) characters, made up of letters or a combination of letters and numbers. To request a personalized tag, there is a $35 fee. To request a replacement personalized tag, there is a $25 fee. It takes about 6-8 months for tags to come in and orders are placed on the 1st and 15th of the month.'

  const { personalizedTagInfo } = useSiteConfig()

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/payment'
    }
  }, [current])

  function handleCheck(e) {
    const value = e.target.checked
    setReplacement(value)
  }

  const fee = replacement ? 25 : 35

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? 'Personalized Tag' : ''} />
      {parseInt(current) >= 0 && current <= 1 && <Marker items={markerItems}/>}
      {current === '' && <Home form='/personalized-tag.pdf' label='Personalized Tag' description={description}>

        <div className={styles.checkHere}>
          <Checkbox {...{
            label: 'Check here if this is a replacement',
            handleCheck,
            checked: replacement,
          }} />
        </div>
        
      </Home>}
      {current === 0 && <UploadDocuments fee={fee} formName='Personalized tag form' />}
      {current === 1 && <UploadSummary formName={formName} infoApi={personalizedTagInfo} formPath='personalized-tag' />}
      <Footer />
    </Context.Provider>
  )
}
