import React from 'react'
import Layout from '../components/layout/layout'
import PersonalizedTag from '../components/personalizedTag/personalizedTag'

export default function PersonalizedTagPage() {
  return (
    <Layout>
      <PersonalizedTag />
    </Layout>
  );
}
