import React from 'react'
import styles from './checkbox.module.scss'
import PropTypes from 'prop-types'

export default function Checkbox({ label, checked, handleCheck}) {
  return (
    <div className={styles.checkbox}>
      <label className={styles.label}>{label}
        <input name="yes" type="checkbox" checked={checked} onChange={handleCheck} />
        <span className={styles.checkmark}></span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  handleCheck: PropTypes.func,
}